import React, { useState } from "react";
import CarouselClient from "../components/CarouselImagesClient/CarouselClient";
import ComplexFormClient from "../components/ComplexForm/ComplexFormClient";
import Footer from "../components/Footer/Footer";
import GoogleMapComponent from "../components/GoogleMaps/GoogleMaps";
import HeroSection1 from "../components/HeroSection/HeroSection1";
import HeroSection2 from "../components/HeroSection/HeroSection2";
import HeroSection3 from "../components/HeroSection/HeroSection3";
import HeroSection4 from "../components/HeroSection/HeroSection4";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import styles from "./General.module.css";

const Client = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <CarouselClient />
      <GoogleMapComponent />
      <HeroSection1 />
      <HeroSection2 />
      <HeroSection3 />
      <HeroSection4 />
      <ComplexFormClient />
      <Footer />
      <a
        className={styles.icon}
        href="https://wa.link/0xjg3p"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/images/whatsapp-icon.png"
          width="60"
          height="60"
          alt="whatsapp icon"
        />
      </a>
    </>
  );
};

export default Client;
