import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  background: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  font-weight: 700;
  border-bottom: 3px solid;
  border-image: linear-gradient(to right, #5c358b, #ff5900) 1;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  text-align: center;

  @media screen and (max-width: 870px) {
    display: flex;
    padding: 0;
  }
`;

export const NavLogo = styled(LinkS)`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  img {
    width: 60%;
    min-width: 100px;
    height: auto;
    max-width: 350px;
  }

  @media screen and (max-width: 870px) {
    margin-left: 5px;
    img {
      width: 30%;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 870px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 80%);
    font-size: 1.5rem;
    cursor: pointer;
    color: #5d358a;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  justify-self: flex-end;
  font-size: clamp(12px, 0.93vw, 16px);

  @media screen and (max-width: 870px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: auto;
  padding: 0 1rem;
`;

export const NavLinks = styled(LinkS)`
  color: #333;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  justify-self: center;
  height: auto;
  list-style: none;

  @media screen and (max-width: 870px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  display: flex;
  align-items: center;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  margin-right: 10px;
  margin-left: 10px;
  ${(props) =>
    props.primary &&
    `
      border-bottom: 3px solid #57338e;
    `};
  &:hover {
    transition: all 0.1s ease-in-out;
    border-bottom: 3px solid #57338e;
  }
`;

export const ContactBtn = styled(LinkS)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  background-color: #ff5900;
  padding: 0.4rem 1.5rem;
  border-radius: 7px;
`;

export const AfterNavContainer = styled.div`
  display: none;

  @media screen and (max-width: 870px) {
    width: 90%;
    display: flex;
    align-items: center;
    background-color: white;
    margin: 0 auto;
    text-align: center;
  }
`;

export const AfterNavWrapper = styled.div`
  width: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AfterNavBtn = styled(LinkR)`
  color: #ff5900;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 14px;
  height: 40px;
  width: 200px;
`;
