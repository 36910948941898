import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CarouselContainer,
  // WrapperContent,
  // WrapperImage,
  // BtnContent,
  // StyledSlider,
  IconsContainerTransportator,
  PackageWrapTransportator,
} from "../CarouselImagesClient/CarouselElementsClient";
import { Link } from "react-scroll";
import styles from "../CarouselImagesClient/CarouselClient.module.css";

const CarouselTransportator = () => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 2000,
  //   autoplaySpeed: 10000,
  //   cssEase: "linear",
  //   arrows: false,
  //   swipe: false,
  // };

  return (
    <>
      <CarouselContainer id="home">
        <div className={styles.video_container} id="home">
          <div height="400" width="1920" className={styles.overlay}></div>
          <video
            className="video-background"
            src="/images/video-transportator.mp4"
            autoPlay
            loop
            muted
            playsInline
            height="400"
            width="1920"
          />
          <p>
            CAUTĂ TRANSPORTUL
            <br /> POTRIVIT PENTRU TINE
          </p>
          <Link className={styles.link} to="form">
            CONTACTEAZĂ-NE
          </Link>
        </div>
        {/* <WrapperContent>
          <p>
            TU AI CAMIONUL.
            <br />
            NOI AVEM MARFA.
          </p>
          <BtnContent to="form">DEVINO PARTENER</BtnContent>
        </WrapperContent> */}
      </CarouselContainer>
      <IconsContainerTransportator>
        <PackageWrapTransportator>
          <img src="/images/arrows.svg" alt="Icon arrows" />
          <p>REȚEA EXTINSĂ DE CANTITĂȚI DISPONIBILE</p>
        </PackageWrapTransportator>
        <PackageWrapTransportator>
          <img src="/images/indicators.svg" alt="Icon indicators" />
          <p>MUNCĂ ÎN PERMANENȚĂ</p>
        </PackageWrapTransportator>
        <PackageWrapTransportator>
          <img src="/images/payment.svg" alt="Icon payment" />
          <p>PLATĂ SIGURĂ ȘI SECURIZATĂ</p>
        </PackageWrapTransportator>
        <PackageWrapTransportator>
          <img src="/images/hands.svg" alt="Icon hands" />
          <p>PARTENERIAT PE TERMEN LUNG</p>
        </PackageWrapTransportator>
      </IconsContainerTransportator>
    </>
  );
};

export default CarouselTransportator;
