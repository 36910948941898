import React from "react";
import styles from "./HeroSection.module.css";
import { Link } from "react-scroll";

const HeroSection3 = () => {
  return (
    <section className={styles.container}>
      <div className={styles.leftside}>
        <h2>Cu ce ne ocupam</h2>

        <img
          className={styles.imageMobile}
          src="/images/hero3.webp"
          alt="Hero section 3"
          width="321"
          height="222"
        />

        <p>
          Prin „soluțiile noastre FIT” de transport vom putea rezolva orice
          nevoie atât de import, cât și de export. Oferim servicii de transport
          național si internațional de mărfuri, pentru a satisface nevoile
          diverse de livrare, îndeplinind cele mai exigente cerințe de
          conformitate în timp și costuri. Analizăm caracteristicile
          încărcăturii precum și nevoile de livrare.
          <br />
          <br /> Parteneriatele noastre cu o rețea largă de transportatori de
          încredere ne permit să oferim un serviciu excelent, garantând
          disponibilitatea, termenele de livrare și acoperire.
        </p>
        <Link className={styles.link} to="form">
          CONTACTEAZĂ-NE
        </Link>
      </div>
      <div className={styles.rightside}>
        <img
          src="/images/hero3.webp"
          width="622"
          height="465"
          alt="Hero section 3"
        />
      </div>
    </section>
  );
};

export default HeroSection3;
