import React from "react";
import styles from "./HeroSection.module.css";
import { Link } from "react-scroll";

const HeroSection5 = () => {
  return (
    <section id="about" className={styles.container}>
      <div className={styles.leftside}>
        <h2>Îți Ținem Flota În Mișcare</h2>
        <img
          className={styles.imageMobile}
          src="/images/hero5.webp"
          alt="Hero section 5"
          width="321"
          height="222"
        />
        <p>
          La baza fiecărei companii sunt oamenii. Credem în promovarea unui
          mediu de lucru divers, în care toată lumea se simte apreciată. Echipa
          noastra este formata din profesionisti cu experienta, care sunt
          ghidați de onestitate și viziune, lucrand întotdeauna pentru a servi
          și susține cat mai bine clienți și transportatorii noștri.
          <br />
          <br /> Având în vedere creșterea noastră dinamică, căutăm colaboratori
          specializati în transport intern și internațional, interesați de o
          cooperare reciproc avantajoasă pe termen lung. Pentru a continua la
          standarde exigente, suntem permanent în căutare de parteneriate
          stabile cu transportatori care abordează activitatea cu integritate și
          pasiune.
          <br />
          <br /> La Fit Logistics construim relații profesionale bazate pe
          comunicare, incredere reciproca si flexibilitate. Recunoastem si
          rasplatim loialitatea și profesionalismul. Colaborarea eficientă cu
          subcontractantii și partenerii noștri de transport este cheia
          managementului nostru logistic de succes.
        </p>
        <Link className={styles.link} to="form">
          CONTACTEAZĂ-NE
        </Link>
      </div>
      <div className={styles.rightside}>
        <img src="/images/hero5.webp" alt="Hero section 5" />
      </div>
    </section>
  );
};

export default HeroSection5;
