import React, { useState } from "react";
import CarouselTransportator from "../components/CarouselImagesTransportator/CarouselTransportator";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "../components/Footer/Footer";
import DetailsTransportator from "../components/DetailsTransportator/DetailsTransportator";
import HeroSection5 from "../components/HeroSection/HeroSection5";
import HeroSection6 from "../components/HeroSection/HeroSection6";
import FormTransportator from "../components/FormTransportator/FormTransportator";
import StepsTransportator from "../components/StepsTransportator/StepsTransportator";
import styles from "./General.module.css";

const Transportator = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <CarouselTransportator />
      <FormTransportator />
      <HeroSection5 />
      <HeroSection6 />
      <DetailsTransportator />
      <StepsTransportator />
      <Footer />
      <a
        className={styles.icon}
        href="https://wa.link/0xjg3p"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/whatsapp-icon.png" alt="whatsapp icon" />
      </a>
    </>
  );
};

export default Transportator;
