import React from "react";
import { useLocation } from "react-router-dom";
import {
  SidebarContainer,
  SidebarLogo,
  Icon,
  CloseIcon,
  SidebarBtnWrap,
  SidebarRoute,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarLinkBtn,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  const { pathname } = useLocation();
  const clientPage = pathname === "/";
  const transportatorPage = pathname === "/transportator";

  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <SidebarLogo to="home" onClick={toggle}>
          <img
            src="/images/logo.webp"
            width="130"
            height="42"
            alt="Logo Sidebar"
          />
        </SidebarLogo>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarBtnWrap onClick={toggle}>
          <SidebarRoute
            style={{
              borderBottom: clientPage ? "3px solid #57338e" : "none",
            }}
            to="/"
          >
            SUNT CLIENT
          </SidebarRoute>
          <SidebarRoute
            style={{
              borderBottom: transportatorPage ? "3px solid #57338e" : "none",
            }}
            to="/transportator"
          >
            SUNT TRANSPORTATOR
          </SidebarRoute>
        </SidebarBtnWrap>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink to="about" onClick={toggle}>
              DESPRE NOI
            </SidebarLink>
            <SidebarLink to="contact" onClick={toggle}>
              CONTACT
            </SidebarLink>
            <SidebarLinkBtn to="form" onClick={toggle}>
              CONTACTEAZA-NE
            </SidebarLinkBtn>
            <div
              className="languages"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                cursor: "pointer",
              }}
            >
              <img
                src="/images/ro.webp"
                width="25"
                height="25"
                alt="ro language"
              />
              <img
                src="/images/eng.webp"
                width="25"
                height="25"
                alt="eng language"
              />
            </div>
          </SidebarMenu>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
