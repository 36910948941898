import React from "react";
import { Link } from "react-scroll";
import styles from "./HeroSection.module.css";

const HeroSection4 = () => {
  return (
    <section className={styles.container2}>
      <div className={styles.leftside2}>
        <img
          src="/images/hero4.webp"
          width="623"
          height="480"
          alt="Hero section 4"
        />
      </div>
      <div className={styles.rightside2}>
        <h2>Ceea ce ne face diferiți</h2>
        <img
          className={styles.imageMobile}
          src="/images/hero4.webp"
          alt="Hero section 4"
          width="321"
          height="222"
        />
        <p>
          Ne străduim să oferim servicii diferențiate pentru fiecare client, ne
          ascultăm colegii și le prețuim contribuția. Această abordare umană
          demonstrează calitatea serviciilor oferite clienților noștri.
          <br />
          <br />
        </p>
        <ul style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <li>Suntem oameni care servesc oamenii</li>
          <li>Ascultare și comunicare eficientă</li>
          <li>Operațiuni rapide și eficiente</li>
          <li>Soluții flexible</li>
          <li>Consultanta si training fara costuri.</li>
        </ul>
        <Link className={styles.link} to="form">
          CONTACTEAZĂ-NE
        </Link>
      </div>
    </section>
  );
};

export default HeroSection4;
