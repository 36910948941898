import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { FaTimes } from "react-icons/fa";

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  font-weight: 700;
`;

export const SidebarLogo = styled(LinkS)`
  position: absolute;
  top: 2rem;
  left: 0rem;
  cursor: pointer;
  width: 30%;
`;

export const CloseIcon = styled(FaTimes)`
  color: #5d358a;
`;

export const Icon = styled.div`
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: 2rem;
`;

export const SidebarWrapper = styled.div`
  color: white;
`;

export const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  gap: 1rem;
  margin-top: 20px;
`;

export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: black;
  cursor: pointer;
`;

export const SidebarLinkBtn = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: #ff5900;
  padding: 2px 2rem;
  border-radius: 6px;
`;

export const SidebarBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5%;
  border-bottom: 2px solid black;
  width: 75%;
`;

export const SidebarRoute = styled(LinkR)`
  color: #ff5900;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border-bottom: 2px solid transparent;

  ${(props) =>
    props.primary &&
    `
      border-bottom: 2px solid #57338e;
    `};

  &:hover {
    transition: all 0.1s ease-in-out;
    border-bottom: 2px solid #57338e;
  }
`;
