import React from "react";
import styles from "./StepsTransportator.module.css";

const StepsTransportator = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>The framework of logistics collaboration</h2>
      <p>
        Our framework highlights how the main challenges and barriers facing
        logistics collaboration can be overcome in three major steps:
        <br />
        <br /> <span className={styles.step}>Step 1</span>: Build awareness,
        especially on the potential benefits and opportunities, so that staff
        start using internal and external factors to create legitimacy and
        urgency.
        <br />
        <br /> <span className={styles.step}>Step 2</span>: Preparation and
        formation. This step involves the identification of challenges and
        barriers and then putting in place the required key success factors. By
        the end of this step there must be adequate trust, top management
        support and willingness to change.
        <br />
        <br /> <span className={styles.step}>Step 3</span>: Implementation and
        management of new collaboration. This involves constant communication of
        the goals and providing necessary support such as IT and resources, as
        well as industrial-wide commitment to enable horizontal collaboration.”
      </p>
      <p className={styles.author}>
        - Professor Chee Wong and Passaraporn Sawasdee
      </p>
    </div>
  );
};

export default StepsTransportator;
