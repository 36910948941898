import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import styles from "./FormTransportator.module.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const FormTransportator = () => {
  const { register, handleSubmit, reset } = useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [modalOpen, setModalOpen] = useState(false);
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    // Get reCAPTCHA token
    const recaptchaToken = await executeRecaptcha("submitForm");

    if (recaptchaToken) {
      const formData = new FormData(formRef.current);
      formData.append("access_key", process.env.REACT_APP_WEB3_KEY);
      // formData.append("g-recaptcha-response", recaptchaToken); // Append the reCAPTCHA token to the form data
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      try {
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData,
        });

        if (res.ok) {
          const responseData = await res.json();
          if (responseData.success) {
            reset();
            setModalOpen(true);
            setIsLoading(false);
          } else {
            console.log("Error", responseData);
            alert("A apărut o eroare, vă rugăm să încercați mai târziu!");
            setIsLoading(false);
          }
        } else {
          throw new Error(`Error ${res.status}`);
        }
      } catch (error) {
        console.error("Error:", error);
        alert("A apărut o eroare la trimiterea formularului.");
        setIsLoading(false);
      }
    } else {
      // If no reCAPTCHA token, notify the user of an issue
      alert(
        "A apărut o eroare la verificarea reCAPTCHA. Vă rugăm să încercați din nou."
      );
    }
  };

  return (
    <>
      <form
        className={styles.formTransportator}
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        id="form"
      >
        <h2>DEVINO PARTENER</h2>
        <div className={styles.row}>
          <input
            {...register("Nume")}
            type="text"
            name="Nume"
            placeholder="Nume"
            required
          />
          <input
            {...register("Email")}
            type="email"
            name="Email"
            placeholder="Email"
            required
          />
          <input
            {...register("Tel")}
            type="tel"
            name="Tel"
            placeholder="Număr de telefon"
            pattern="\d{8,15}"
            required
          />
        </div>
        <div className={styles.row}>
          <input
            {...register("tip_autovehicul")}
            type="text"
            name="tip_autovehicul"
            placeholder="Tipul de autovehicul"
            required
          />
          <select
            {...register("numar_vehicule")}
            name="numar_vehicule"
            required
          >
            <option value="">Nr. de autovehicule</option>
            <option value="1-5">1-5</option>
            <option value="5-10">5-10</option>
            <option value="10+">10+</option>
          </select>
          <input
            {...register("nume_firma")}
            type="text"
            name="nume_firma"
            placeholder="Nume firmă"
            required
          />
        </div>
        <div className={styles.row}>
          <input
            {...register("delegat")}
            type="text"
            name="delegat"
            placeholder="Delegat"
            required
          />
          <input
            {...register("cui")}
            type="text"
            name="cui"
            placeholder="CUI FIRMĂ"
            required
          />
          <button type="submit" disabled={isLoading}>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "VREAU SĂ DEVIN PARTENER"
            )}
          </button>
        </div>
      </form>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.box}>
          <div className={styles.wrapper_modal}>
            <Typography
              className={styles.modal_text}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Felicitări!
            </Typography>
            <Typography
              className={styles.modal_text}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              O să te contactăm în curând!
            </Typography>
            <button
              className={styles.previous_button}
              type="button"
              onClick={() => setModalOpen(false)}
            >
              Înapoi
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FormTransportator;
