import React from "react";
import { Link } from "react-scroll";
import styles from "./HeroSection.module.css";

const HeroSection6 = () => {
  return (
    <section className={styles.container2}>
      <div className={styles.leftside2}>
        <img src="/images/hero6.webp" alt="Hero section 6" />
      </div>
      <div className={styles.rightside2}>
        <h2>Ce avantaje au partenerii Fit Logistics</h2>
        <img
          className={styles.imageMobile}
          src="/images/hero6.webp"
          alt="Hero section 6"
          width="321"
          height="222"
        />
        <ul>
          <li>
            Beneficiază de colaborare pe termen lung, in baza unor contracte
            stabilite.
          </li>
          <li>Obțin plăți sigure și rapide a facturilor de transport.</li>
          <li>Au acces la un număr mare de încărcături din toata Europa.</li>
          <li>Au activitate continua pe tot parcursul anului.</li>
          <li>Sunt sustinuti de echipa noastra, cu care comunica permanent.</li>
          <li>
            Primesc suportul nostru pentru reînnoirea flotei și dezvoltarea
            solidă a afacerii.
          </li>
        </ul>
        <Link className={styles.link} to="form">
          CONTACTEAZĂ-NE
        </Link>
      </div>
    </section>
  );
};

export default HeroSection6;
