import styled from "styled-components";

export const TitleDetails = styled.h1`
  color: #57338e;
  text-align: center;
  font-weight: 700;
  font-size: clamp(20px, 1.875vw, 36px);
  padding: 5% 0;
  width: 80%;
  margin: 0 auto;
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

export const WrapperContentDetails = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 7%;

  img {
    width: 6%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;

    img {
      width: 20%;
      margin: 7% 0;
    }
  }
`;

export const TextDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3%;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const TitleContentDetails = styled.h3`
  color: #57338e;
  font-size: clamp(16px, 1.25vw, 24px);
  font-weight: 700;
  margin-bottom: 1%;

  @media screen and (max-width: 768px) {
    margin-bottom: 3%;
  }
`;

export const ParagraphContentDetails = styled.p`
  font-size: clamp(16px, 1.041vw, 20px);
  color: black;
  font-weight: 300;
`;
