import React from "react";
import {
  TitleDetails,
  ContainerDetails,
  WrapperContentDetails,
  TextDetails,
  TitleContentDetails,
  ParagraphContentDetails,
} from "./DetailsTransportatorElements";

const DetailsTransportator = () => {
  return (
    <>
      <TitleDetails>CE ESTE IMPORTANT PENTRU NOI</TitleDetails>
      <ContainerDetails>
        <WrapperContentDetails>
          <img src="/images/hands.svg" alt="Deal transporator" />
          <TextDetails>
            <TitleContentDetails>
              Parteneriat Pe Termen Lung
            </TitleContentDetails>
            <ParagraphContentDetails>
              La Fit Logistics ne străduim să oferim cel mai bun serviciu
              posibil și să fim cea mai bună companie de transport . Vă
              considerăm un partener cu care vrem să creștem, așa că, indiferent
              dacă aceasta este prima dvs. expediere sau nu, ne vom asigura că
              întregul proces este o bucurie pentru dvs și echipa dvs.
            </ParagraphContentDetails>
          </TextDetails>
        </WrapperContentDetails>
        <WrapperContentDetails>
          <img src="/images/world.svg" alt="Worldwide transporator" />
          <TextDetails>
            <TitleContentDetails>Comunicare</TitleContentDetails>
            <ParagraphContentDetails>
              Comunicarea deschisă în timpul fiecărei etape a procesului de
              expediere este un mare beneficiu într-un parteneriat. Considerăm
              comunicarea ca și un fundament pe care construim relații durabile
              cu clienții noștri. Feedback-ul pozitiv contribuie la realizarea
              serviciilor oferite de noi, dar totodată acceptăm și criticile,
              care ne motivează, ne ajută să devenim mai buni, așa că în cazul
              în care identificați probleme în serviciile noastre, nu ezitați să
              ni le semnalați.
            </ParagraphContentDetails>
          </TextDetails>
        </WrapperContentDetails>
        <WrapperContentDetails>
          <img src="/images/support.svg" alt="Support transporator" />
          <TextDetails>
            <TitleContentDetails>Servicii Premium</TitleContentDetails>
            <ParagraphContentDetails>
              Expertiză în logistică, tehnologie și experiență în transportul de
              mărfuri în România, Europa și în întreaga lume. Logistics
              expertise, technology, and experience in moving freight across
              Romania, Europe and around the world.
            </ParagraphContentDetails>
          </TextDetails>
        </WrapperContentDetails>
        <WrapperContentDetails>
          <img src="/images/premium.svg" alt="Premium transporator" />
          <TextDetails>
            <TitleContentDetails>
              Ne orientăm pentru a fi Fit nevoilor dumneavoastră
            </TitleContentDetails>
            <ParagraphContentDetails>
              Fit Logistics oferă un serviciu cu un grad ridicat de
              flexibilitate. Punem nevoile clienților și partenerilor noștri pe
              primul loc și muncim din greu pentru a ne asigura că suntem
              capabili să oferim tot ce este necesar într-un timp util și la un
              ritm care funcționează.
            </ParagraphContentDetails>
          </TextDetails>
        </WrapperContentDetails>
      </ContainerDetails>
    </>
  );
};

export default DetailsTransportator;
